<template>
	<div class="dashboard-container">
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="end">
							<el-col :span="1">
								<vxe-button @click="editCreditConfigs">保存</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="$headerStyle" :row-style="$rowStyle"
				 align="center" class="vxe-table-element" height="750" row-id="notice_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :tooltip-config="{enterable: false}" :data="table.list"
				 :loading="loading">
					<vxe-table-column field="name" title="名录" />
					<vxe-table-column title="分值" show-overflow="title">
						<template v-slot="{row}">
							<el-input size="mini" v-if="row.code != 'REWARD' && row.code != 'PUNISHMENT'" v-model="row.score" placeholder="请输入" @keyup="inputClick($event)" type="number" max="100" min="1"
							 maxlength="3" class="inputClass"/>
						</template>
					</vxe-table-column>
					<vxe-table-column title="设置" show-overflow="title">
						<template v-slot="{row}">
							<el-input size="mini" v-if="row.code != 'emotion'" v-model="row.deductCondition" max="100" min="1" maxlength="3" placeholder="请输入"
							 type="number" class="inputClass" @keyup="inputClick($event)"/>
							<span v-if="row.code == 'safe'">%</span>
							<span v-if="row.code == 'lawless' || row.code == 'drive' || row.code == 'serviceq'">次</span>
						</template>
					</vxe-table-column>
					<vxe-table-column title="增删分数" show-overflow="title">
						<template v-slot="{row}">
							<el-input size="mini" v-model="row.deductScore" placeholder="请输入" @keyup="inputClick1($event)" type="number" class="inputClass"/>
						</template>
					</vxe-table-column>
				</vxe-table>
			</el-row>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'Index',
		data() {
			return {
				loading: false,
				table: {
					list: [],
				},
			}
		},
		created() {
			this.getList()
		},
		methods: {
			inputClick1(e) {
				if (e.target.value.indexOf('.') != '-1') {
					e.target.value = e.target.value.split('.')[0];
				}
			},
			getList() {
				this.loading = true
				this.$axiosReq('/archives/server/escort/web/creditConfig', null, null, 'get').then(res => {
					this.table.list = res.data;
				}).finally(() => {
					this.loading = false
				})
			},
			editCreditConfigs() {
				this.loading = true
				let val = 0;
				this.table.list.forEach((item,index) => {
					if (item.score && index < 5) {
						val = val + Number(item.score);
					}
				})
				if (val != 100) {
					this.$message({
						showClose: true,
						message: "总分数必须等于100",
						type: "error"
					});
					this.loading = false;
					return false
				}
				this.$axiosReq('/archives/server/escort/web/creditConfig/editCreditConfigs', this.table.list, null, 'post').then(
					res => {
						this.$message({
							showClose: true,
							message: res.msg || res.data.msg,
							type: "success"
						});
						this.getList()
					}).finally(() => {
					this.loading = false;
				})
			},
			inputClick(e) {
				var val = e.target.value;
				if ((val == 0 && val.length == 1) || !val) {
					return false
				}
				if (val > 100) {
					this.$message({
						showClose: true,
						message: "请输入100以内正整数",
						type: "error"
					});
					e.target.value = 100;
					return false
				}
				let flag = new RegExp("^[1-9]([0-9])*$").test(val);
				if (!flag) {
					this.$message({
						showClose: true,
						message: "请输入100以内正整数",
						type: "error"
					});
					e.target.value = val.split('.')[0];
				}
			}
		}
	}
</script>

<style scoped>
	.inputClass {
		border: 0;
		/*border-bottom: 1px solid #000000;*/
		background-color: transparent;
		width: 100px;
		height: 30px;
		line-height: 30px;
		text-align: center;
	}
</style>
